@import 'styles/theme';

.FooterColumn {
  .items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .link {
    & + .link {
      margin-top: theme($spacings, 5);
    }
  }
}
