@import 'styles/theme';

.FooterLogo {
  display: flex;

  .logo {
    width: 52px;
    height: 60px;

    &.theme-amber {
      path {
        fill: theme($colors, 'black');
      }
    }
  }
}
