@import 'styles/theme';

.Breadcrumb {
  display: flex;
  align-items: center;
  justify-content: center;

  @include from('md') {
    justify-content: flex-start;
  }

  .separator {
    display: inline-flex;
    margin: 0 theme($spacings, 5);
  }

  .noLink {
    cursor: none;
    pointer-events: none;
    opacity: 0.65;
  }
  &.theme-product {
    .noLink {
      cursor: none;
      pointer-events: none;
      opacity: 0.65;
    }

    .item {
      & a {
        transition: color 0.25s ease;
      }

      &:hover a {
        color: theme($colors, 'black');
      }
    }

    .active {
      pointer-events: none;

      a {
        color: theme($colors, 'black');
      }
    }
  }

  &.theme-footer {
    .separator {
      margin: 0 theme($spacings, 10);
    }
    .item {
      transition: opacity 0.25s ease;
      opacity: 0.35;
      height: theme($spacings, 50);

      @include from('lg') {
        height: unset;
      }

      &:hover,
      &:active {
        opacity: 1;
      }
    }
  }
}
