@import 'styles/theme';

.FooterMobileColumns {
  width: 100%;

  .panel {
    border-bottom: 1px solid theme($colors, 'mine-shaft');
  }

  .panelHead {
    display: flex;
    padding-top: theme($spacings, 30);
    padding-bottom: theme($spacings, 8);
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: theme($colors, 'silver-chalice');
  }

  .panelBody {
    padding-top: theme($spacings, 10);
    padding-bottom: theme($spacings, 10);

    .item {
      display: flex;
      align-items: center;

      &:not(:first-child) {
        margin-top: theme($spacings, 5);
      }
    }
  }

  &.theme-black {
    .panel {
      border-bottom: 1px solid theme($colors, 'mine-shaft');
    }

    .panelHead {
      color: theme($colors, 'silver-chalice');
    }
  }

  &.theme-william {
    .panel {
      border-bottom: 1px solid theme($colors, 'silver-chalice');
    }

    .panelHead {
      color: theme($colors, 'concrete');
    }
  }

  &.theme-amber {
    .panel {
      border-bottom: 1px solid theme($colors, 'gray');
    }

    .panelHead {
      color: theme($colors, 'black');
    }
  }

  &.theme-william,
  &.theme-eunry,
  &.theme-irish-coffee {
    .panel {
      border-bottom: 1px solid theme($colors, 'silver-chalice');
    }

    .panelHead {
      color: theme($colors, 'concrete');
    }
  }
}
