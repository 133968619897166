@import 'styles/theme';
.Breadcrumb {
  display: flex;
  position: relative;
  flex-wrap: wrap;

  .item {
    display: flex;
    align-items: center;
    margin-bottom: theme($spacings, 4);
  }
}
