@import 'styles/theme';

.Footer {
  padding-top: theme($spacings, 35);
  background: theme($colors, 'cod-gray');

  @include switchDesktopMobileClasses('lg', flex);
  @include from('lg') {
    padding-top: theme($spacings, 20);
  }

  .top,
  .body {
    grid-column: 1/-1;
  }

  .reassurance {
    display: flex;
    color: theme($colors, 'white');
    flex-direction: column;

    .item {
      height: theme($spacings, 50);
      text-align: center;
      width: fit-content;
      margin: 0 auto;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include from('lg') {
      flex-direction: row;
      justify-content: space-between;
      .item {
        width: initial;
        height: unset;
        margin: 0 theme($spacings, 5);
        text-align: center;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .breadcrumb {
    @include from('lg') {
      margin-top: theme($spacings, 40);
    }
  }

  .body {
    margin-top: theme($spacings, 15);
    margin-bottom: theme($spacings, 15);

    @include from('lg') {
      display: flex;
      margin-top: theme($spacings, 200);
      margin-bottom: theme($spacings, 25);
    }

    .bodyLeft,
    .bodyRight {
      display: flex;
    }

    .bodyLeft {
      width: 100%;
      flex-direction: column;

      @include from('lg') {
        flex-direction: row;
        @include applyColumnWidth('width', 17, $desktop);
      }
    }

    .logo {
      @include applyColumnWidth('width', 4, $desktop);
      margin-left: auto;
      margin-right: auto;
      justify-content: center;

      @include from('lg') {
        margin-left: 0;
        margin-right: 0;
        justify-content: unset;

        @include applyColumnWidth('width', 2, $desktop);
        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'margin-right',
            'gutters': 0,
            'gaps': 1,
            'columns': 0,
          )
        );
      }
    }

    .desktopColumnItem {
      @include applyColumnWidth('width', 3, $desktop);
      @include setPropertyValue(
        (
          'values': $desktop,
          'propertyName': 'margin-right',
          'gutters': 0,
          'gaps': 1,
          'columns': 0,
        )
      );
    }

    .mobileColumns {
      margin-top: theme($spacings, 5);
    }

    .bodyRight {
      margin-top: theme($spacings, 20);

      @include from('lg') {
        margin-top: 0;
        @include applyColumnWidth('width', 7, $desktop);
      }
    }

    .social,
    .customService {
      display: flex;
      width: 100%;
      justify-content: space-between;

      @include from('lg') {
        flex-direction: column;
        align-items: flex-start;
        justify-content: unset;
        @include applyColumnWidth('width', 3, $desktop);
        @include setPropertyValue(
          (
            'values': $desktop,
            'propertyName': 'margin-right',
            'gutters': 0,
            'gaps': 1,
            'columns': 0,
          )
        );
      }
    }

    .socialLink {
      margin: 0 theme($spacings, 5);
      text-align: center;
      height: theme($spacings, 50);

      @include from('lg') {
        height: unset;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      & + .socialLink {
        @include from('lg') {
          margin-top: theme($spacings, 5);
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    .customerService {
      line-height: 1.5;
      flex-direction: column;
    }
  }

  .bottom {
    background: theme($colors, 'white');

    @include from('lg') {
      padding-top: theme($spacings, 5);
      padding-bottom: theme($spacings, 5);
    }

    .content {
      grid-column: 1/-1;
      display: flex;

      @include from('lg') {
        align-items: center;
      }
    }

    .copyright {
      color: theme($colors, 'gray');
      margin-right: auto;
    }

    .bottomLinks,
    .bottomIcons {
      .bottomLink,
      .iconWrapper {
        height: theme($spacings, 50);

        margin-right: theme($spacings, 10);

        @include from('lg') {
          margin-right: theme($spacings, 20);
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .bottomLinks {
      margin-right: theme($spacings, 10);

      @include from('lg') {
        display: flex;
      }
    }

    .bottomIcons {
      display: flex;
      margin-left: auto;
      align-items: center;

      @include from('lg') {
        margin-left: theme($spacings, 20);
      }

      .iconWrapper {
        display: inline-flex;
        height: 14px;
        width: auto;
      }
    }
  }

  // Themes
  &.theme-black {
    background: theme($colors, 'cod-gray');
  }

  &.theme-william {
    background: theme($colors, 'william');
  }

  &.theme-eunry {
    background: theme($colors, 'eunry');
  }

  &.theme-irish-coffee {
    background: theme($colors, 'irish-coffee');
  }

  &.theme-amber {
    background: theme($colors, 'amber');

    .reassurance {
      color: theme($colors, 'cod-gray');
    }
  }
}
